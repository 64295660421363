import React from "react";
// import RightSideImgae from "../../assets/img/banner-GHL.png";
import RightSideImgae from "../../assets/img/rightsideimg.png";
import WaveBorder from "../../assets/img/borderimg.png";
import MedaiBox from "../../assets/img/media.png";
import ConvertLeadIntoCustomers from "../../assets/img/convert-leads-into-customers.png";
import YourCustomWhiteLabelPlatform from "../../assets/img/yourCustom-label-platform.jpg";
import SetUpMembership from "../../assets/img/set-up-membership-areas.png";
import CaptureFreshLeads from "../../assets/img/capture-fresh-leads.png";
import AutomatedBooking from "../../assets/img/automatedBooking.png";
import Slide from "../../Components/Slideshow UI/Slide";
import left from "../../assets/img/section-divider-bg-left.png";
import Dividerright from "../../assets/img/section-divider-bg-right.png";
import IncreaseYourDeal from "../../assets/img/Increase-Your-Deal-Closings.png";
import CountUp from "react-countup";
import "./go-heighlevel.css";
import { Helmet } from "react-helmet";
// import RightSideImgae from '/assets/img/right-side-img.png'

function GoHighLevel() {
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Go High Level - Your Space for Ultimate Marketing Solutions
        </title>
        <meta
          name="description"
          content="Explore how Elicit Digital can help you take your business to new heights with our specialized expertise in utilizing Go High Level. Get a free consultation now!"
        />
        <meta
          name="keywords"
          content="gohighlevel, gohighlevel marketing, Marketing Funnel, gohighlevel pricing"
        />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta
          property="og:title"
          content=" Go High Level - Your Space for Ultimate Marketing Solutions"
        />
        <meta
          property="og:site_name"
          content=" Elicit Digital-Web Development Company"
        />
        <meta property="og:url" content="www.elicit.digital" />
        <meta
          property="og:description"
          content="Explore how Elicit Digital can help you take your business to new heights with our specialized expertise in utilizing Go High Level. Get a free consultation now!"
        />
        <meta property="og:image" itemProp="image" content="/elicitlogo.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta
          name="twitter:title"
          content=" Go High Level - Your Space for Ultimate Marketing Solutions"
        />
        <meta
          name="twitter:description"
          content="Explore how Elicit Digital can help you take your business to new heights with our specialized expertise in utilizing Go High Level. Get a free consultation now!"
        />
        <meta name="twitter:image" content="/elicitlogo.png" />
      </Helmet>
      <>
        <div className="go-high-level">
          <div className="first-section"></div>
          <div className="second-level-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-xs-12 text-centerb text-center">
                  <h3 className="text-center">
                    <b>Empower Your Business:</b>
                    <br />
                    Simplify Your Marketing and Sales Funnels with HighLevel
                    <br />
                    One platform, Endless possibilities
                  </h3>
                  <button className="trials-btn">
                    <div>Hire a GHL Expert Today</div>
                    <span className="sub-heading-button">
                      Level up your marketing automation
                    </span>
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12  text-center">
                  <div className="right-side-section">
                    <img src={RightSideImgae} alt="high-level" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="third-level-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-4 col-xs-12  text-center">
                  <div className="right-side-section">
                    <img src={RightSideImgae} alt="high-level" />
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-xs-12 text-center">
                  <div className="card p-4">
                    <div className="business-help">
                      <h2 className="pb-0">
                        <strong>
                          Maximize Business Potential with GHL Experts Today!
                        </strong>
                      </h2>
                      <p className="text-center p-0 pb-3">
                        HighLevel is an all-in-one marketing platform that
                        streamlines your workflow and provides everything you
                        need to achieve your marketing goals—from lead
                        generation to customer retention.
                      </p>
                      <div className=" align-items-center d-flex justify-content-between count-boxcard">
                        <div className="card-box">
                          <h3>
                            <CountUp
                              start={0}
                              end={300}
                              duration={6}
                              decimals={0}
                              suffix="+"></CountUp>
                          </h3>
                          <p>Customers</p>
                        </div>
                        <div className="card-box">
                          <h3>
                            <CountUp
                              start={0}
                              end={500}
                              duration={6}
                              decimals={0}
                              suffix="+"></CountUp>
                          </h3>
                          <p>Website Development</p>
                        </div>
                        <div className="card-box">
                          <h3>
                            <CountUp
                              start={0}
                              end={1000}
                              duration={6}
                              decimals={0}
                              suffix="+"></CountUp>
                          </h3>
                          <p>Built Funnels</p>
                        </div>
                        <div className="card-box">
                          <h3>
                            <CountUp
                              start={0}
                              end={1000}
                              duration={6}
                              decimals={0}
                              suffix="+"></CountUp>
                          </h3>
                          <p>CRM Workflow Automation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="heading-title-section">
            <div className="container">
              <div className="wave-lines">
                <img src={WaveBorder} alt="" />
              </div>
            </div>
            <div className="heading-title">
              <div className="text-center">
                <div>
                  <h2>
                    <strong>Grow Your Business Faster With</strong>
                    {/* <strong>Streamline your marketing and sales funnel with HighLevel's</strong> */}
                  </h2>
                </div>
                <div>
                  <h2>
                    <strong>HighLevel's Marketing Automation Tools</strong>
                  </h2>
                </div>
                <p className="mt-3">
                  Unify your marketing tools with a single platform.
                </p>
              </div>
            </div>
          </div>
          <div className="fourt-level-section">
            <div className="container">
              <div>
                <div className="questiontitle">
                  <p>Do you want to attract new customers?</p>
                </div>
                <div className="ans-title">
                  <p>
                    By learning from our engaged community, we can discover the
                    cutting-edge tactics that leading digital marketers are
                    implementing to achieve online success.
                  </p>
                </div>
              </div>
              <div>
                <div className="questiontitle">
                  <p>Do you want to Improve customer retention?</p>
                </div>
                <div className="ans-title">
                  <p>
                    Our powerful sales and marketing platform combines marketing
                    and sales and streamlines your workflow to deliver
                    exceptional client experiences.
                  </p>
                </div>
              </div>
              <div>
                <div className="questiontitle">
                  <p>Do you want to build strategies for business growth?</p>
                </div>
                <div className="ans-title">
                  <p>
                    HighLevel offers access to a network of experienced digital
                    marketers, potentially helping businesses grow through
                    increased sales and service diversification.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="arrow-left new-ui-design">
            <img src={Dividerright} alt="UI/UX Design Services" />
          </section>
          <div className="five-level-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12  ">
                  <div className="media-box custom-img">
                    <img src={MedaiBox} alt="media" />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="build-your-project">
                    <h2>
                      <strong>Building a Digital Marketing System</strong>
                    </h2>
                    <p>
                      Without having to "duct-tape" several platforms together,
                      you may have all the tools you need on one platform!
                    </p>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                        <p className="subtitels">Capture Lead</p>
                        <p>
                          Turn your website into a lead generation machine with
                          landing pages, surveys, forms, and more!
                        </p>
                      </div>
                    </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                        <p className="subtitels">Revert</p>
                        <p>
                          Send leads automated messages via voicemail, incoming
                          calls, SMS, emails, Facebook Messenger, and more!
                        </p>
                      </div>
                    </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                        <p className="subtitels">Convert</p>
                        <p>
                          Make use of the built-in features to plan
                          appointments, gather payments, and monitor analytics!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="arrow-left new-ui-design">
            <img src={left} alt="UI/UX Design Services" />
          </section>
          <div className="five-level-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 order-last order-lg-first  ">
                  <div className="build-your-project">
                    <h2>
                      <strong>Capture Fresh Leads</strong>
                    </h2>
                    <p>
                      For marketers, HighLevel is a full-suite platform. The
                      platform comes with a fully functional page builder for
                      lead generation.
                    </p>
                      <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                    <p className="subtitels">
                      Create Landing Pages, Funnels, And Complete Websites
                    </p>
                    <p>
                      You can build fully-featured websites with specific menus
                      using our user-friendly platform. Build attractive and
                      effective landing pages in one place!
                        </p>
                        </div>
                    </div>
                      <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                    <p className="subtitels">
                      Surveys Forms With Drag And Drop
                    </p>
                    <p>
                      The ability to collect leads using surveys and capture
                      forms is already integrated. You have two options: embed
                      them on your own websites or integrate directly with our
                      page builder.
                        </p>
                        </div>
                    </div>
                      <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                    <p className="subtitels">Booking Appointments Online</p>
                    <p>
                      For a lot of organizations, scheduling and capturing
                      appointments is the initial stage. To make it easy for you
                      to capture appointments, we have developed our own
                      calendar application inside of HighLevel.
                        </p>
                        </div>
                        </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="media-box">
                    <img src={CaptureFreshLeads} alt="media" />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="arrow-left new-ui-design">
            <img src={Dividerright} alt="UI/UX Design Services" />
          </section>
          <div className="five-level-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12  ">
                  <div className="media-box">
                    <img src={ConvertLeadIntoCustomers} alt="media" />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="build-your-project">
                    <h2>
                      <strong>Convert Leads Into Customers</strong>
                    </h2>
                    <p>
                      What you can achieve after capturing the lead is the
                      foundation of HighLevel.
                    </p>
                      <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                    <p className="subtitels">
                      Quickly Personalize Your Follow-Up Campaigns
                    </p>
                    <p>
                      With our multi-channel follow-up campaigns, you can
                      automatically follow up with your leads and get their
                      engaged responses.
                        </p>
                        </div>
                    </div>
                      <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                    <p className="subtitels">
                      Develop Multi-Channel Marketing Campaign
                    </p>
                    <p>
                      You can interact with your leads via Facebook Messenger,
                      SMS/MMS, voicemail drops, phone connect, emails, and even
                      voicemail drops with HighLevel.
                        </p>
                        </div>
                    </div>
                      <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                    <p className="subtitels">
                      Bidirectional Communication On Any Device
                    </p>
                    <p>
                      Our comprehensive mobile application enables you to
                      interact with your leads across all platforms.
                    </p>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="arrow-left new-ui-design">
            <img src={left} alt="UI/UX Design Services" />
          </section>
          <section className=" second-Wireframes container">
            <div className="row">
              <div className="col-lg-7 col-md-6 order-last order-lg-first">
                <div className="build-your-project">
                  <h2>
                    <strong>
                      Automated Booking System With Full Functionality
                    </strong>
                  </h2>
                  <p>
                    Effortlessly schedule appointments with potential leads and
                    prospects directly to your calendar.
                  </p>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">Automated Nurturing Conversations</p>
                  <p>
                    Generate text chats with the intention of scheduling
                    appointments on calendars without involving any human
                    communication.
                      </p>
                      </div>
                  </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">
                    Complete Customization Of Messaging
                  </p>
                  <p>To modify the messaging, use our campaign builder.</p>
                  </div>
                  </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                      <p className="subtitels">
                    Integration Of Artificial Intelligence
                  </p>
                  <p>
                    HighLevel enables you to utilize AI and machine learning to
                    handle conversations effectively.
                      </p>
                      </div>
                      </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6  ">
                <div className="main-Gathring">
                  <div className="High-image">
                    <img src={AutomatedBooking} alt="UI/UX Design Services" />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="arrow-left new-ui-design">
            <img src={Dividerright} alt="UI/UX Design Services" />
          </section>{" "}
          {/* thrid */}
          <section className=" second-Wireframes container">
            <div className="row">
              <div className="col-lg-5 col-md-6  ">
                <div className="main-Gathring">
                  <div className="High-image">
                    <img src={SetUpMembership} alt="UI/UX Design Services" />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="build-your-project">
                  <h2>
                    <strong>Set Up Membership Areas</strong>
                  </h2>
                  <p>
                    Build a vibrant community for your business or clients with
                    our membership platform.
                  </p>
                  <div className="d-flex gap-2 subitems-gohight">
                    <span className="icons-level">
                      <svg
                        aria-hidden="true"
                        className="e-fas-arrow-right"
                        viewBox="0 0 448 512"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                      </svg>
                    </span>
                    <div>
                      <p className="subtitels">
                        Comprehensive Couse Management
                      </p>
                      <p>
                        Create complete courses with unlimited video hosting and
                        unlimited user access.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex gap-2 subitems-gohight">
                    <div>
                      <span className="icons-level">
                        <svg
                          aria-hidden="true"
                          className="e-fas-arrow-right"
                          viewBox="0 0 448 512"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <p className="subtitels">
                        Provide Both Free And Paid Courses
                      </p>
                      <p>
                        Our comprehensive platform allows you to sell courses or
                        offer them for free, all within a single product!
                      </p>
                    </div>
                  </div>
                  <div className="d-flex gap-2 subitems-gohight">
                    <div>
                      <span className="icons-level">
                        <svg
                          aria-hidden="true"
                          className="e-fas-arrow-right"
                          viewBox="0 0 448 512"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <p className="subtitels">Unlimited Access!</p>
                      <p>
                        For your courses, HighLevel provides an infinite number
                        of users, offers, and products. You can make courses for
                        your own company or for your clients!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="arrow-left new-ui-design">
            <img src={left} alt="UI/UX Design Services" />
          </section>{" "}
          {/* thrid */}
          <section className=" second-Wireframes container">
            <div className="row">
              <div className="col-lg-7 col-md-6 order-last order-lg-first">
                <div className="build-your-project">
                  <h2>
                    <strong>Increase Your Deal Closings</strong>
                  </h2>
                  <p>
                    Access a vibrant community of top-performing and innovative
                    digital marketers globally. Obtain comprehensive training
                    and resources essential for kickstarting or scaling your
                    business.
                  </p>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">Handle Your Workflow And Pipeline</p>
                  <p>
                    Utilize our integrated pipeline management feature to
                    monitor lead progression through various sales funnel
                    stages.
                      </p>
                      </div>
                  </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">Accept Customer Payments</p>
                  <p>
                    We directly integrate with Stripe, enabling you to accept
                    payments on websites, funnels, and even during appointment
                    bookings.
                      </p>
                      </div>
                  </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">
                    Centralized Analytics And Reporting
                  </p>
                  <p>
                    Our dashboard provides a comprehensive overview of lead
                    locations and the revenue generated at each phase.
                      </p>
                      
                </div>
                </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6  ">
                <div className="main-Gathring">
                  <div className="High-image">
                    <img src={IncreaseYourDeal} alt="UI/UX Design Servi ces" />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="arrow-left new-ui-design">
            <img src={Dividerright} alt="UI/UX Design Services" />
          </section>{" "}
          {/* thrid */}
          <section className=" second-Wireframes container">
            <div className="row">
              <div className="col-lg-5 col-md-6  ">
                <div className="main-Gathring">
                  <div className="High-image">
                    <img
                      src={YourCustomWhiteLabelPlatform}
                      alt="UI/UX Design Services"
                    />
                    <div className="text-center">
                      <button className="trials-btn">
                        <div>Hire a GHL Expert Today</div>
                        <span className="sub-heading-button">
                          Level up your marketing automation
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="build-your-project">
                  <h2>
                    <strong>Your Custom White Label Platform</strong>
                  </h2>
                  <p>
                    Our users enjoy offering added value to their clients. With
                    HighLevel, you have the capability to develop a custom app
                    available in the App Store.
                  </p>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">Market It To Your Customers</p>
                  <p>
                    Provide access to the app for an extra charge, or include it
                    as part of your services!
                      </p>
                      </div>
                  </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">Set Your Own Price For It</p>
                  <p>
                    We allow you to sell it at any price that works for you, as
                    we provide unlimited users and accounts.
                      </p>
                      </div>
                  </div>
                    <div className="d-flex gap-2 subitems-gohight">
                      <div>
                        <span className="icons-level">
                          <svg
                            aria-hidden="true"
                            className="e-fas-arrow-right"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                  <p className="subtitels">Extra Source Of Revenue</p>
                  <p>
                    The CRM and mobile app enable you to increase your charges
                    by introducing a platform access fee to your clients.
                      </p>
                </div>
                </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div>
            <div className="technology-servicess">
            <div className="container spacing">
                <h2 className="section-heading text-center mb-3">
                Build Your Remote Developer Workforce With Elicit
                </h2>
                <h4>
                Here are the top remote developer services that Elicit provides
                </h4>
                <Slide />
            </div>
            </div>
      </div> */}
          {/* ******************** */}
          <div className="last-section-level-section  ">
            <div className="container">
              <div>
                <h2>
                  <strong>
                    Join The Most Accomplished Marketers In The World
                  </strong>
                </h2>
                <p className="mt-2">
                  Find Out What HighLevel Can Do For Your Business &amp; You
                </p>
                <button className="trials-btn">
                  <div>Hire a GHL Expert Today</div>
                  <span className="sub-heading-button">
                    Level up your marketing automation
                  </span>
                </button>
                <p className="mt-2">
                  No Commitments, No Contract, Anytime Cancellation
                </p>
              </div>
            </div>
          </div>
          {/* <section>
        <div className='container'>
        <h2 className='text-center'><strong>Join The Most Successful Marketers On The Planet</strong></h2>
                <div className='go-heighlevel-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Business</th>
                                <th>Tools</th>
                                <th>Level</th>
                                <th>Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Marketing</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>Go highlevel for Real Estate</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>Go highlevel Funnel</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>Kajabi Expert</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>Trigger & Campaign Setup</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>Go highlevel CRM</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>Marketing <automation></automation></td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                            <tr>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                                <td>GHL</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
        </section> */}
         
        </div>
      </>
    </>
  );
}

export default GoHighLevel;
