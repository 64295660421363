import React, { version } from "react";
import { Helmet } from "react-helmet";

import validate from "./ValidateInfo";
import UseForm from "./Useform";
import Slide from "../../Components/Slideshow UI/Slide";
import Android from "../../assets/img/android.jpg";
import GitexImage from "../../assets/img/Gitex-Global.svg";
import ElicitLifeslider from "./ElicitLifeslider";
import Ios from "../../assets/img/iOs.jpg";
// import Hybrid from "../../assets/img/hybrid.jpg";
import Hybrid from "../../assets/img/hybridicon.jpg";
import Iwatch from "../../assets/img/iwatch.jpg";
import Web from "../../assets/img/web.jpg";
import Ui from "../../assets/img/ui.jpg";
import Iot from "../../assets/img/iot.jpg";
import Beacon from "../../assets/img/beacon.jpg";
import NorthStar from "../../assets/img/northstarlogo.png";
import Mysiteqr from "../../assets/img/mysiteqr.png";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
// import LogoIcon from "../../assets/img/logo-img.jpg";
import Lerandesh from "../../assets/img/Learndesh.png";

import { transform } from "typescript";
////popvideo///
// import Slidergitex from "../Homeslidergitex/Slidergitex";
// import VideoPupop from "../Homepagepopup/Popupvideo";
const Home = (props) => {
  const { handleChange, values, handleSubmit, errors } = UseForm(validate);
  return (
    <div>
      {/* <Slidergitex /> */}
      <Helmet>
        <title>
          Meet Mobile App Development Experts at GITEX North Star 2024
        </title>
        <meta
          name="title"
          content="Meet Mobile App Development Experts at GITEX North Star 2024"
        />
        <meta
          name="description"
          content="Join Elicit at GITEX Expand North Star 2024, a leading web and mobile app development company delivering innovative digital solutions for business growth."
        />
        <meta
          name="keywords"
          content="web and mobile app development company, mobile app development company"
        />

        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta
          property="og:description"
          content="Join Elicit at GITEX Expand North Star 2024, a leading web and mobile app development company delivering innovative digital solutions for business growth."
        />
  <meta property="og:site_name" content="Elicit Digital"/>
  <meta property="og:url" content="https://www.elicit.digital"/>
  <meta property="og:type" content="website"/>
  <meta property="og:image" content="https://www.elicit.digital/elicitlogo.png"/>
  <meta name="twitter:card" content="summary_large_image"/>
  <meta name="twitter:site" content="@elicitdigital"/>
  <meta name="twitter:title" content="Hire Our Experts For Web And Mobile App Development"/>
  <meta name="twitter:description" content="Are you looking to build a high-quality web or mobile app? Hire our experienced developers for custom app development solutions that drive results. Get expert services tailored to your business needs."/>
  <meta name="twitter:image" content="https://www.elicit.digital/elicitlogo.png"/>
      </Helmet>
      <section className="home-banner">
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            {/* <VideoPupop isOpen={true} /> */}
            <div className="col-lg-8 col-sm-12 col-md-12 banner-left-content">
              <h2 className="Gitex-title">
                Meet <span className="textGradieant"> Elicit </span>
                &nbsp; at
                <br />
                <img src={NorthStar} alt="northstar" /> 2024 &nbsp;{" "}
              </h2>
              {/* <img src={NorthStar} /> */}
              {/* <h2 className="Gitex-hallnumber">Hall Number : Stand H1-C61</h2> */}
              {/* <div className="countdown-box">
                <ul className="countdown-list">
                  <li>
                    <span>
                      <CountUp
                        start={0}
                        end={10}
                        duration={6}
                        decimals={0}
                        suffix="+"></CountUp>
                    </span>
                    Years in the business
                  </li>
                  <li>
                    <span>
                      <CountUp
                        start={100}
                        end={500}
                        duration={5.5}
                        decimals={0}
                        suffix=" + "></CountUp>
                    </span>
                    Solutions delivered
                  </li>
                  <li>
                    <span>
                      <CountUp
                        start={0}
                        end={150}
                        duration={5.5}
                        decimals={0}
                        suffix="+"></CountUp>
                    </span>
                    Technology experts
                  </li>
                  <li>
                    <span>
                      <CountUp
                        start={0}
                        end={50}
                        duration={5}
                        decimals={0}
                        prefix="$"
                        suffix="M+"></CountUp>
                    </span>
                    Raised by our clients
                  </li>
                </ul>
              </div> */}
              <div className=" gap-4">
                <div className="mb-4">
                  <a
                    href="https://maps.google.com/?q=Dubai+Harbour,+UAE"
                    target="_blank">
                    <div className="d-flex">
                      <span className="gitex-location">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#f48303"
                          version="1.1"
                          id="Capa_1"
                          width="25px"
                          height="25px"
                          viewBox="0 0 395.71 395.71">
                          <g>
                            <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" />
                          </g>
                        </svg>
                        Stand H1-C61 Dubai HARBOUR , UAE
                      </span>
                    </div>
                  </a>
                </div>
                <div className="mb-4">
                  <div className="d-flex">
                    <span className="gitex-location-date">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        viewBox="-5.4 0 98.4 98.4">
                        <g
                          id="Group_4"
                          data-name="Group 4"
                          transform="translate(-822.7 -241.5)">
                          <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M899.4,254.3H833.6a8.92,8.92,0,0,0-8.9,8.9V329a8.92,8.92,0,0,0,8.9,8.9h65.8a8.92,8.92,0,0,0,8.9-8.9V263.2A8.92,8.92,0,0,0,899.4,254.3Z"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_25"
                            data-name="Line 25"
                            x2="21.2"
                            transform="translate(842.6 283.7)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_26"
                            data-name="Line 26"
                            x2="45.9"
                            transform="translate(842.6 302)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_27"
                            data-name="Line 27"
                            y2="19.6"
                            transform="translate(853.6 243.5)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_28"
                            data-name="Line 28"
                            y2="19.6"
                            transform="translate(879.4 243.5)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                        </g>
                      </svg>
                      <span> 13-16 Oct 2024</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 mt-4 meetingbtn-link">
                  <a href="https://calendly.com/aakash1392" target="blank">
                    <button className="meetingbtn">
                      {" "}
                      Book Your Slot!
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.231"
                        height="11.738"
                        viewBox="0 0 24.231 11.738">
                        {" "}
                        <path
                          id="Path_1487529"
                          data-name="Path 1487529"
                          d="M542.348,1353.029H522.873c-.142,0-.284.006-.426,0a.944.944,0,0,1-.019-1.888c.141-.01.284,0,.425,0h19.6c-.171-.184-.27-.3-.376-.4-.949-.946-1.9-1.887-2.846-2.839a.957.957,0,0,1-.066-1.391.936.936,0,0,1,1.388.044q2.423,2.392,4.829,4.8a.927.927,0,0,1,.014,1.456q-2.42,2.429-4.862,4.835a.927.927,0,0,1-1.354.039.951.951,0,0,1,.039-1.39q1.4-1.412,2.815-2.8a3.557,3.557,0,0,1,.415-.307Z"
                          transform="translate(-521.511 -1346.214)"
                          fill="#000"></path>{" "}
                      </svg>{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-12">
              <div className="qrcode">
                <p className="text-white text-center mb-0 ">Scan QR Code</p>
                <img src={Mysiteqr} alt="elicit-qr" />
              </div>
              {/* <div className="banner-section-form">
                <h4>Connect with us.</h4>
                <form onSubmit="return validateform()">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="required">{errors.name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="required">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="form-controlAppearance">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Contact Number"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                      />
                      {errors.mobile && (
                        <span className="required">{errors.mobile}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="textarea-border">
                      <textarea
                        id="description"
                        placeholder="Type your message here..."
                        name="description"
                        value={values.text}
                        onChange={handleChange}
                        autoComplete="off"></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="section-services1 ">
        <section className="section-services">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-4 ">
              <div className="service-img2">
                <img src={Service} alt="service" />
              </div>
            </div> */}
              <div className="col-lg-12 ">
                <div className="row">
                  <div className="col-lg-9">
                    <h2>
                      <span>Package Of</span> Services
                    </h2>
                    <h3>
                      With a blend of technology partner rather than a vendor
                    </h3>
                  </div>
                  <div className="col-lg-3 col-connect">
                    <h3>To Discuss a Project</h3>
                    <Link
                      to="/get-in-touch"
                      title="Let's Connect"
                      className="btn Lets-Connect">
                      Let's Connect
                    </Link>
                  </div>
                  <div className="row pl-0 pr-0">
                    <div className="col-lg-3 col-sm-6">
                      <Link to="/android-app-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img src={Android} alt="android app developers" />
                          </span>
                          Android App Development
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <Link to="/ios-app-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img src={Ios} alt="ios app developer" />
                          </span>
                          iOS App Development
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <Link to="/hybrid-app-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img src={Hybrid} alt="Hybrid app development" />
                          </span>
                          Hybrid App Development
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <Link to="/wearable-app-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img
                              src={Iwatch}
                              alt="weaerable development services"
                            />
                          </span>
                          Wearable App Development
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6 ">
                      <Link to="/web-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img src={Web} alt="web development agency" />
                          </span>
                          Web Development
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6 ">
                      <Link to="/ui-ux-design-services">
                        <div className="androidService">
                          <span className="icon">
                            <img src={Ui} alt="Uiux design" />
                          </span>
                          UI/UX Designing Services
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <Link to="/iot-software-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img src={Iot} alt="Iot development services" />
                          </span>
                          IOT Software Development
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <Link to="/beacon-development-services">
                        <div className="androidService">
                          <span className="icon">
                            <img
                              src={Beacon}
                              alt="Beacon development services"
                            />
                          </span>
                          Beacon Development
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <p>
                  Working at the Elicit Digital is a unique experience that
                  inspires you to explore your passion and it is a place where
                  your talents are nurtured and cultivated. You can imagine to
                  innovate with leading-edge technologies on some of the coolest
                  projects.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="technology-services">
        <div className="container">
          <h2 className="text-center mb-3">Technologies we work with</h2>
          <Slide />
        </div>
      </div>

      <section className="learndess">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="learndesh-img">
                <img src={Lerandesh} alt="" />
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="learndress-prg">
                <p className="learn-text">
                  At Elicit, our company's lifestyle is a mesmerizing blend of
                  expertise in Learndash and the recognition we receive from the
                  plugin itself. We take immense pride in being acknowledged as
                  experts by Learndash for their exceptional plugin
                  customization needs. Our skill set goes beyond just that; we
                  guarantee seamless migration of data and entire websites from
                  platforms like Teachable, Kajabi, Learnworld, and Thinkfic
                  within a specified time frame. This ability to effortlessly
                  transition between various e-learning platforms has become
                  increasingly valuable in today's digital age where online
                  education and e-learning are gaining immense popularity.
                </p>
                <p className="learn-text">
                  Life at Elicit is vibrant, filled with both hard work and
                  countless celebrations that bring all Elicitians together
                  during festivals and events organized within the organization.
                  With a strong core value of stewardship embedded within each
                  individual at Elicit, there is a pervasive sense of
                  belongingness wherein every team member feels ownership
                  towards celebrating our collective achievements.{" "}
                </p>
                <p className="learn-text">
                  We strive to create an environment that encourages continuous
                  learning, adaptation, and implementation while ensuring a
                  healthy work-life balance for all individuals on our team. At
                  Elicit, we believe in fostering creativity by granting our
                  team members the freedom to explore their innovative spirits
                  within this nurturing atmosphere.{" "}
                </p>
                <p className="learn-text">
                  Being known as a great place to work isn't solely based on the
                  quality deliverables we provide for clients; it encompasses
                  how well we take care of each individual who contributes to
                  making Elicit what it truly is—an extraordinary community
                  comprising dedicated professionals whom we affectionately call
                  "Elicitians."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="slider-lifeElicit container new-slide">
        <h2 className="text-center mb-5">
          <span className="Hteam-span"></span>Life At Elicit
        </h2>
        <ElicitLifeslider />
        <h3 className="all-team text-center ">
          <a className="team-section-link " href="/life-at-elicit">
            {" "}
            Read More{" "}
          </a>
        </h3>
      </div>
    </div>
  );
};
export default Home;
