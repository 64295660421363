import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GitexImage from "../../assets/img/Gitex-Global.svg";
import NorthStar from "../../assets/img/northstarlogo.png";
import ProductImage from "../../assets/img/td-macimage.png";
import LMSImage from "../../assets/img/lms-mac.png";
import Aakash from "../../assets/img/aaksah5.png";
import Kriti from "../../assets/img/kritei.png";
import GhlImage from "../../assets/img/ghlImage.png";


const CountdownTimer = ({ targetDate, onComplete }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Trigger the onComplete callback when the countdown ends
    if (
      timeLeft.days === 0 &&
      timeLeft.hours === 0 &&
      timeLeft.minutes === 0 &&
      timeLeft.seconds === 0
    ) {
      clearInterval(timer);
      if (onComplete) onComplete(); // Notify the parent component
    }

    return () => clearInterval(timer);
  }, [timeLeft, onComplete]);

  return (
    <div className="countdown-timer">
      <div className="timer-box">
        <span className="time">{timeLeft.days}</span>
        <span className="label">Days</span>
      </div>
      <div className="timer-box">
        <span className="time">{timeLeft.hours}</span>
        <span className="label">Hr</span>
      </div>
      <div className="timer-box">
        <span className="time">{timeLeft.minutes}</span>
        <span className="label">Min</span>
      </div>
      <div className="timer-box">
        <span className="time">{timeLeft.seconds}</span>
        <span className="label">Sec</span>
      </div>
    </div>
  );
};

const GitexLandingPage = ( props ) =>
{
    const [currentIndex, setCurrentIndex] = useState(0);

    // Array of slide data
    const slides = [
      {
        name: "Aakash Singhal",
        title: "CEO/Founder",
        description:
          "Meet Aakash, a visionary leader dedicated to providing exceptional services and driving strategic growth for our clients and partners.",
        image: Aakash,
        link: "https://calendly.com/aakash1392",
      },
      {
        name: "Kriti Raghuvanshi",
        title: "Client Acquisition Manager",
        description:
          "Meet Kriti, a mentor dedicated to delivering quality software and helping teams create innovative solutions for clients' toughest challenges",
        image: Kriti,
        link: "https://calendly.com/aakash1392",
      },
    ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [slides.length]);

   const [isCountdownActive, setIsCountdownActive] = useState(true);
   const targetDate = "October 13, 2024 12:00:00";

   // Callback function to hide the section once the countdown ends
   const handleCountdownComplete = () => {
     setIsCountdownActive(false);
   }; 
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Join Us at GITEX North Star 2024 for Mobile App Innovations
        </title>
        <meta
          name="title"
          content=" Join Us at GITEX  North Star 2024 for Mobile App Innovations"
        />
        <meta
          name="description"
          content="Join Elicit at GITEX Expand North Star 2024 for innovative tech and digital solutions! Discover cutting-edge products, partnerships, & future-driven strategies."
        />
        <meta
          name="keywords"
          content="GITEX Global 2024  Elicit at GITEX 2024 gitex dubai , gitex dubai 2024 Elicit at GITEX Global 2024 ,gitex tech week , gitex technology, Mobile App Development Company at Gitex Global, gitex event 2024
, gitex technology,gitex exhibition,gitex global, gitex north star 2024 , gitex expand north star , expand north star 2024 , north star registration, expand north star dubai , expand north star dubai harbor , gitex expand north star 2024 "
        />

        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="Geography" content="India" />
        <meta name="author" content="Elicit Digital" />
        <meta name="copyright" content="Elicit Digital" />
        <meta name="distribution" content="Global" />
        <meta name="publisher" content="www.elicit.digital" />
        <meta name="Search Engine" content="https://www.google.com/" />
        <meta
          property="og:title"
          content="Join Us at GITEX  North Star 2024 for Mobile App Innovations"
        />
        <meta
          property="og:site_name"
          content=" Elicit Digital-Web Development Company"
        />
        <meta property="og:url" content="www.elicit.digital" />
        <meta
          property="og:description"
          content="Join Elicit at GITEX Expand North Star 2024 for innovative tech and digital solutions! Discover cutting-edge products, partnerships, & future-driven strategies."
        />
        <meta
          property="og:image"
          itemprop="image"
          content="https://www.elicit.digital/elicitlogo.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Elicit_Digital" />
        <meta
          name="twitter:title"
          content="Join Us at GITEX  North Star 2024 for Mobile App Innovations"
        />
        <meta
          name="twitter:description"
          content="Join Elicit at GITEX Expand North Star 2024 for innovative tech and digital solutions! Discover cutting-edge products, partnerships, & future-driven strategies."
        />
      </Helmet>
      <section className="home-banner gitexlandingpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 banner-left-content">
              <h2 className="Gitex-title text-center">
                Meet <span className="textGradieant"> Elicit </span>
                &nbsp; at
                <br />
                <img src={NorthStar} alt="northstar" /> 2024 &nbsp;{" "}
              </h2>

              <div className="d-flex justify-content-center gap-4 flex-wrap">
                <div className="mb-4">
                  <a
                    href="https://maps.google.com/?q=Dubai+Harbour,+UAE"
                    target="_blank">
                    <div className="d-flex">
                      <span className="gitex-location">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#f48303"
                          version="1.1"
                          id="Capa_1"
                          width="25px"
                          height="25px"
                          viewBox="0 0 395.71 395.71">
                          <g>
                            <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" />
                          </g>
                        </svg>
                        Stand H1-C61 Dubai HARBOUR , UAE
                      </span>
                    </div>
                  </a>
                </div>
                <div className="mb-4">
                  <div className="d-flex">
                    <span className="gitex-location-date">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        viewBox="-5.4 0 98.4 98.4">
                        <g
                          id="Group_4"
                          data-name="Group 4"
                          transform="translate(-822.7 -241.5)">
                          <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M899.4,254.3H833.6a8.92,8.92,0,0,0-8.9,8.9V329a8.92,8.92,0,0,0,8.9,8.9h65.8a8.92,8.92,0,0,0,8.9-8.9V263.2A8.92,8.92,0,0,0,899.4,254.3Z"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_25"
                            data-name="Line 25"
                            x2="21.2"
                            transform="translate(842.6 283.7)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_26"
                            data-name="Line 26"
                            x2="45.9"
                            transform="translate(842.6 302)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_27"
                            data-name="Line 27"
                            y2="19.6"
                            transform="translate(853.6 243.5)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_28"
                            data-name="Line 28"
                            y2="19.6"
                            transform="translate(879.4 243.5)"
                            fill="none"
                            stroke="#f48303"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                        </g>
                      </svg>
                      <span> 13-16 Oct 2024</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4 text-center">
                  <a href="https://calendly.com/aakash1392" target="blank">
                    <button className="meetingbtn mx-auto">
                      {" "}
                      Book Your Slot!
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.231"
                        height="11.738"
                        viewBox="0 0 24.231 11.738">
                        {" "}
                        <path
                          id="Path_1487529"
                          data-name="Path 1487529"
                          d="M542.348,1353.029H522.873c-.142,0-.284.006-.426,0a.944.944,0,0,1-.019-1.888c.141-.01.284,0,.425,0h19.6c-.171-.184-.27-.3-.376-.4-.949-.946-1.9-1.887-2.846-2.839a.957.957,0,0,1-.066-1.391.936.936,0,0,1,1.388.044q2.423,2.392,4.829,4.8a.927.927,0,0,1,.014,1.456q-2.42,2.429-4.862,4.835a.927.927,0,0,1-1.354.039.951.951,0,0,1,.039-1.39q1.4-1.412,2.815-2.8a3.557,3.557,0,0,1,.415-.307Z"
                          transform="translate(-521.511 -1346.214)"
                          fill="#000"></path>{" "}
                      </svg>{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner-right-content"></div>
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="container">
          <h3 className="text-center mb-4 pb-4">
            <b>Our Products</b>
          </h3>
          <div className="my-4 py-4">
            <div className="  row" style={{ alignItems: "center" }}>
              <div className="col-lg-6 col-md-6 col-sm-12 success-top-left">
                <div className="product-deatils">
                  <h5>Talk to Your Document (TTYD)</h5>
                  <p>
                    your AI-powered assistant for efficient document handling.
                    Simply upload any document, and our intelligent solution
                    will read it for you. Need specific information? Just ask,
                    and Ttyd will provide clear, concise answers, helping you
                    save time, stay productive, and focus on what really
                    matters. Let Ttyd handle the heavy lifting—so you don’t have
                    to!
                  </p>
                  <ul>
                    <li>
                      Tired of sifting through endless documents to find what
                      you need?
                    </li>
                    <li>Frustrated with how much time and energy it takes?</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 success-top-right">
                <div className="product-images">
                  <img
                    src={ProductImage}
                    className="product-image"
                    alt="product"
                  />
                  <div className="text-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 pt-4 reverseproduct">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-6 col-md-6 col-sm-12 success-top-left">
                <div className="product-images">
                  <img src={LMSImage} className="product-image" alt="LMS" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 success-top-right">
                <div className="product-deatils">
                  <h5>Learning Management System (LMS) </h5>
                  <p>
                    Our Learning Management System (LMS) is designed to empower
                    educators and learners with a seamless and interactive
                    online learning experience. Here’s a snapshot of the key
                    features:
                  </p>
                  <ul>
                    <li>Teachable offers easy course creation & management</li>
                    <li>
                      Teachable has smooth student engagement tools. Efficient
                    </li>
                    <li>
                      progress tracking & reporting Teachable offers Content
                    </li>
                    <li>
                      Flexibility & Multimedia Support for different file types.
                    </li>
                    <li>
                      Our Product is GDPR Compliant and support scrom files.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="my-4 py-4">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="product-deatils">
                  <h5>Elicit Funnel</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptates delectus quasi quisquam a quibusdam temporibus
                    numquam, deserunt harum quas soluta sed pariatur possimus
                    nulla, ex animi eos ipsam accusamus quia, necessitatibus
                    cupiditate dolor odit praesentium. Provident saepe nostrum
                    consectetur a!
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="product-images">
                  <img src={GhlImage} className="product-image" alt="GHL" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="text-center mt-4">
            <a href="https://calendly.com/aakash1392" target="blank">
              <button className="meetingbtn mx-auto">
                {" "}
                <b> Request A Demo</b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.231"
                  height="11.738"
                  viewBox="0 0 24.231 11.738">
                  {" "}
                  <path
                    id="Path_1487529"
                    data-name="Path 1487529"
                    d="M542.348,1353.029H522.873c-.142,0-.284.006-.426,0a.944.944,0,0,1-.019-1.888c.141-.01.284,0,.425,0h19.6c-.171-.184-.27-.3-.376-.4-.949-.946-1.9-1.887-2.846-2.839a.957.957,0,0,1-.066-1.391.936.936,0,0,1,1.388.044q2.423,2.392,4.829,4.8a.927.927,0,0,1,.014,1.456q-2.42,2.429-4.862,4.835a.927.927,0,0,1-1.354.039.951.951,0,0,1,.039-1.39q1.4-1.412,2.815-2.8a3.557,3.557,0,0,1,.415-.307Z"
                    transform="translate(-521.511 -1346.214)"
                    fill="#000"></path>{" "}
                </svg>{" "}
              </button>
            </a>
          </div>
        </div>
      </section>
      {isCountdownActive && (
        <section className="remaining-days-section">
          <div className="container text-center">
            <h4 className="text-white">Can't wait to see you there!</h4>
            <CountdownTimer
              targetDate={targetDate}
              onComplete={handleCountdownComplete}
            />
          </div>
        </section>
      )}
      <section className="meetingslider remaining-days-section">
        <div className="container text-center">
          <h4 className="text-white">
            Meet Elicit Tech Experts at GITEX Global 2024
          </h4>
          <p className="text-center text-white">
            Our Team is Here to Drive Your Success and Spark Innovation
          </p>
          <div className="slider-container">
            <div
              className="slider-wrapper"
              style={{
                transform: `translateX(${
                  (-currentIndex * 100) / (currentIndex + 1)
                }%`,
              }}>
              {slides.map((slide, index) => (
                <div key={index} className="ourcustomslider-card">
                  <div>
                    <h5>{slide.name}</h5>
                    <h6>{slide.title}</h6>
                    <p>{slide.description}</p>
                    <a
                      href="https://calendly.com/aakash1392"
                      target="blank"
                      className="d-inline-block">
                      <button className="meetingbtn">
                        {" "}
                        Book Your Slot!
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.231"
                          height="11.738"
                          viewBox="0 0 24.231 11.738">
                          {" "}
                          <path
                            id="Path_1487529"
                            data-name="Path 1487529"
                            d="M542.348,1353.029H522.873c-.142,0-.284.006-.426,0a.944.944,0,0,1-.019-1.888c.141-.01.284,0,.425,0h19.6c-.171-.184-.27-.3-.376-.4-.949-.946-1.9-1.887-2.846-2.839a.957.957,0,0,1-.066-1.391.936.936,0,0,1,1.388.044q2.423,2.392,4.829,4.8a.927.927,0,0,1,.014,1.456q-2.42,2.429-4.862,4.835a.927.927,0,0,1-1.354.039.951.951,0,0,1,.039-1.39q1.4-1.412,2.815-2.8a3.557,3.557,0,0,1,.415-.307Z"
                            transform="translate(-521.511 -1346.214)"
                            fill="#000"></path>{" "}
                        </svg>{" "}
                      </button>
                    </a>
                    <div className="our-expertimage">
                      <img src={slide.image} alt="expert" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="meet-section-gitex">
        <h2>
          Why Meet <span className="textGradieant">Elicit</span> at GITEX 2024?
        </h2>
        <div className="custom-container-accordion">
          <div className="row">
            <div className="col-lg-6 col-sm-12 success-top-left">
              <div className=" cardcontent">
                <h3>Tailored Digital Solutions</h3>
                <p>
                  Elicit specializes in creating custom web and mobile
                  applications that are tailored to your specific business
                  needs. Whether you're looking to enhance customer engagement,
                  streamline operations, or launch a new product, our team
                  delivers scalable, user-centric solutions.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 success-top-right">
              <div className=" cardcontent">
                <h3>Cutting-Edge Technologies</h3>
                <p>
                  We harness the power of emerging technologies such as AI,
                  blockchain, IoT, and cloud computing to build apps that not
                  only meet current demands but also anticipate future trends.
                  Learn how we integrate advanced tech to keep your business
                  ahead of the curve
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 success-top-left">
              <div className=" cardcontent">
                <h3>End-to-End Development Services</h3>
                <p>
                  From conceptualization to deployment, Elicit offers a full
                  suite of development services. We handle everything from UI/UX
                  design, back-end development, and API integrations to
                  post-launch maintenance and support. Meeting with us at GITEX
                  allows you to explore how we can manage your entire digital
                  journey.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 success-top-right">
              <div className=" cardcontent">
                <h3>Proven Expertise Across Industries</h3>
                <p>
                  We’ve worked with a wide range of industries, from healthcare
                  and finance to retail and education. Our solutions are
                  adaptable to unique sector needs, ensuring you get the
                  expertise necessary to navigate complex business challenges
                  with ease.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 success-top-left">
              <div className=" cardcontent">
                <h3>Collaborate for Long-Term Growth</h3>
                <p>
                  At GITEX 2024, we’re looking to build meaningful partnerships.
                  Meeting Elicit opens the door to collaborations that
                  prioritize long-term growth. Whether you're a startup or an
                  established enterprise, our development strategies are
                  designed to scale with your business.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 success-top-right">
              <div className=" cardcontent">
                <h3>Explore Innovative App Concepts</h3>
                <p>
                  Let’s explore new ideas! We're ready to brainstorm and discuss
                  innovative app concepts that can transform your business
                  operations, customer experience, or internal workflows. Our
                  creative approach ensures you’ll walk away with fresh
                  perspectives and actionable insights.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-6 col-sm-12">
              <div className=" cardcontent">
                <h3>Discover our Latest Innovations and Technologies</h3>
                <p>
                  You can see live demonstrations, which help you better
                  understand how a cutting new edge technologies are impacting
                  people life for better.
                </p>
              </div>
            </div> */}
          </div>
        </div>
        <div className="text-center">
          <a
            href="https://calendly.com/aakash1392"
            target="blank"
            className="d-inline-block">
            <button className="meetingbtn">
              {" "}
              Book Your Slot!
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.231"
                height="11.738"
                viewBox="0 0 24.231 11.738">
                {" "}
                <path
                  id="Path_1487529"
                  data-name="Path 1487529"
                  d="M542.348,1353.029H522.873c-.142,0-.284.006-.426,0a.944.944,0,0,1-.019-1.888c.141-.01.284,0,.425,0h19.6c-.171-.184-.27-.3-.376-.4-.949-.946-1.9-1.887-2.846-2.839a.957.957,0,0,1-.066-1.391.936.936,0,0,1,1.388.044q2.423,2.392,4.829,4.8a.927.927,0,0,1,.014,1.456q-2.42,2.429-4.862,4.835a.927.927,0,0,1-1.354.039.951.951,0,0,1,.039-1.39q1.4-1.412,2.815-2.8a3.557,3.557,0,0,1,.415-.307Z"
                  transform="translate(-521.511 -1346.214)"
                  fill="#000"></path>{" "}
              </svg>{" "}
            </button>
          </a>
        </div>
      </section>
      <section className="taking-challenges ">
        <div className="container">
          <h3 className="text-center">Business Engagement Models Elicit</h3>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 success-top-left">
              <div className="card-box">
                <h4> Fixed Price Model </h4>
                <p>
                  If you choose this model, you must have a fair idea about the
                  project requirements, scope, and deadline
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 success-top-left">
              <div className="card-box">
                <h4>Time and Material</h4>
                <p>
                  It is a flexible model that allows changes to project
                  specifications at any given time. If the project s scope and
                  features are not clear to you, this is the right model.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 success-top-left">
              <div className="card-box">
                <h4>Dedicated Resource /Teams</h4>
                <p>
                  Our Dedicated Resource/Team Model is a flexible, transparent
                  and effective working model that shows results that you have
                  always wanted!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-0">
        <div className="gitext-event-deatils">
          <div className="">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 success-top-left">
                <div className="evet-date">
                  <h4 className="text-white ">Event Detail</h4>
                  <div className="d-flex gap-4 my-3">
                    <div>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#fff"
                        version="1.1"
                        id="Capa_1"
                        width="25px"
                        height="25px"
                        viewBox="0 0 395.71 395.71">
                        <g>
                          <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <h5 className="text-white">Event Location</h5>
                      <p className="text-white">
                        Hall no -Stand H1-C61 , Dubai Harbour, UAE
                      </p>
                    </div>
                  </div>
                  <div className="d-flex gap-4 my-3">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        viewBox="-5.4 0 98.4 98.4">
                        <g
                          id="Group_4"
                          data-name="Group 4"
                          transform="translate(-822.7 -241.5)">
                          <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M899.4,254.3H833.6a8.92,8.92,0,0,0-8.9,8.9V329a8.92,8.92,0,0,0,8.9,8.9h65.8a8.92,8.92,0,0,0,8.9-8.9V263.2A8.92,8.92,0,0,0,899.4,254.3Z"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_25"
                            data-name="Line 25"
                            x2="21.2"
                            transform="translate(842.6 283.7)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_26"
                            data-name="Line 26"
                            x2="45.9"
                            transform="translate(842.6 302)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_27"
                            data-name="Line 27"
                            y2="19.6"
                            transform="translate(853.6 243.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                          <line
                            id="Line_28"
                            data-name="Line 28"
                            y2="19.6"
                            transform="translate(879.4 243.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="4"
                          />
                        </g>
                      </svg>
                    </div>
                    <div>
                      <h5 className="text-white">Event Date</h5>
                      <p className="text-white">13 - 16 OCT 2024</p>
                    </div>
                  </div>
                  <div className="d-flex gap-4 my-3">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25px"
                        height="25px"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 7V12L10.5 14.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <h5 className="text-white">Timing</h5>
                      <p className="text-white">
                        Sunday-Wednesday, 10:00-17:00 GST
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 px-0 success-top-right">
                <div
                  style={{
                    display: "table",
                    width: "100%",
                    height: "100%",
                    minHeight: "300px",
                  }}>
                  <div
                    style={{
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      background: "#fff",
                    }}>
                    <iframe
                      src="https://maps.google.com/maps?q=Harbour%20Dubai&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                      frameBorder="0"
                      scrolling="no"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        zIndex: 2,
                        display: "block",
                      }}
                      title="Google Map of Harbour Dubai"></iframe>
                    <a
                      href="https://www.taxuni.com/colorado-income-tax-calculator/"
                      style={{
                        color: "#fff",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 0,
                      }}>
                      Colorado Income Tax Calculator
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="power-business ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <h4>Power Up Your Business with Elicit</h4>
              <p>
                Join the ranks of 500+ satisfied clients who’ve transformed
                their digital presence.
              </p>
              <a href="https://calendly.com/aakash1392" target="blank">
                <button className="meetingbtn ">
                  {" "}
                  Request Meeting
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.231"
                    height="11.738"
                    viewBox="0 0 24.231 11.738">
                    {" "}
                    <path
                      id="Path_1487529"
                      data-name="Path 1487529"
                      d="M542.348,1353.029H522.873c-.142,0-.284.006-.426,0a.944.944,0,0,1-.019-1.888c.141-.01.284,0,.425,0h19.6c-.171-.184-.27-.3-.376-.4-.949-.946-1.9-1.887-2.846-2.839a.957.957,0,0,1-.066-1.391.936.936,0,0,1,1.388.044q2.423,2.392,4.829,4.8a.927.927,0,0,1,.014,1.456q-2.42,2.429-4.862,4.835a.927.927,0,0,1-1.354.039.951.951,0,0,1,.039-1.39q1.4-1.412,2.815-2.8a3.557,3.557,0,0,1,.415-.307Z"
                      transform="translate(-521.511 -1346.214)"
                      fill="#000"></path>{" "}
                  </svg>{" "}
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GitexLandingPage;
