import { React, useState } from "react";
import { FaApple } from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { IoMdCall } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import "./Footer.css";
// import ShowModal from './ShowModal'

const Footer = () => {
  // const [icon, setIcon] = useState(true)
  // const [show, setShow] = useState(false)

  // const handleClick = () => {
  //   setIcon(!icon)
  //   setShow(true)
  // }
  return (
    <div>
      {/* <div className="processStep">
          <span onClick={() => handleClick()} type="button" class="btn" data-toggle="modal" data-target='#Modal1'>
            {icon ? <i class="fa fa-pencil" ></i> : <i class="fa fa-close" ></i>}
          </span>
      </div> */}

      {/* <div class="modal" id="Modal1" >
        {show && <ShowModal setShow={(d) => setShow(d)} setIcon={(i) => { setIcon(i) }} />}
      </div> */}

      <section className="section-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 ">
              <p>
                {" "}
                <Link className="logo" to="/">
                  <img src={Logo} alt="elicit digital logo" />
                </Link>{" "}
              </p>
              <p>
                Elicit has much planned for the future, working with great
                clients and continued software development. We always welcome
                talent to our team.
              </p>
           
            </div>
            <div className=" col-lg-6  col-md-11 services-footer">
              <h3>Services</h3>
              <ul className="footer-services">
                <Link to="/android-app-development-services">
                  <li>
                      <span className="icon">
                        <AiFillAndroid />
                      </span>{" "}
                      Android App Development
                  </li>
                </Link>

                <Link to="/ios-app-development-services">
                  <li>
                      <span className="icon">
                        {" "}
                        <FaApple />{" "}
                      </span>
                      iOS App Development
                  </li>
                </Link>

                <Link to="/web-development-services">
                  <li>
                      <span className="icon">
                        <AiFillAndroid />
                      </span>
                      Web Development
                  </li>
                </Link>

                <Link to="/ui-ux-design-services">
                  <li>
                      <span className="icon">
                        <AiFillAndroid />
                      </span>
                      UI/UX Designing Services
                  </li>
                </Link>

                <Link to="/iot-software-development-services">
                  <li>
                      <span className="icon">
                        <FaApple />
                      </span>
                      IOT Software Development
                  </li>
                </Link>
              
                <Link to="/hybrid-app-development-services">
                  <li>
                      <span className="icon">
                        <AiFillAndroid />
                      </span>
                      <div className="footer_service_Hybrid">
                        Hybrid App Development
                      </div>
                  </li>
                </Link>
              </ul>
            </div>
            <div className=" col-lg-3 col-md-8">
              <h3>Contact Us</h3>
              <ul className="contact-list">
                <li>
                  <div className="d-flex">
                    <div className="footer-location">
                      <ImLocation />
                    </div>
                    <p>
                      Office Plot No. 345, 346, <br />
                      Floor : 3 Scheme 54 PU4, Indore, Madhya Pradesh 452010
                    </p>
                  </div>
                </li>
                <li>
                  <a
                    href="mailto:sales@elicit.digital"
                    title="sales@elicit.digital"
                  >
                    <div className="d-flex">
                      <div className="footer-location">
                        <FiMail />
                      </div>
                      <div>
                        <p>sales@elicit.digital</p>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="tel:9111555876" title="+91 9111555876">
                    <div className="d-flex">
                      <div className="footer-location">
                        <IoMdCall />
                      </div>
                      <div>
                        <p>+919111555876</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className=" socile-media-icons-custom social-medias">
                <a
                  href="https://www.linkedin.com/company/elicit-digital/"
                  target="/_blank"
                  className="linked"
                >
                  <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.instagram.com/elicit.digital/"
                  target="/_blank"
                  className="insta"
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.youtube.com/@elicitdigital"
                  target="/_blank"
                  className="youtube"
                >
                 <i class="fa fa-youtube-play" aria-hidden="true"></i>
                </a>
                <a
                  href="https://in.pinterest.com/elicitdigital/"
                  target="/_blank"
                  className="pinterest"
                >
                <i class="fa fa-pinterest" aria-hidden="true"></i>
                </a>  
                <a
                  href="https://twitter.com/elicitdigital"
                  target="/_blank"
                  className="twitter"
                >
                   <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "#fff", width: "18px"}} viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                </a>  
                <a
                  href="https://www.facebook.com/ElicitInfotech"
                  target="/_blank"
                  className="facebook"
                >
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.behance.net/elicitdigital"
                  target="/_blank"
                  className="facebook"
                >
                  <i class="fa fa-behance" aria-hidden="true"></i>
                </a>
              </div>
          <div className="row copyright-card copyright-section">
            <div className="col-lg-6 copy-right2">
              Copyright © <span>{new Date().getFullYear()}</span> Elicitdigital
              | All Rights Reserved.
            </div>
            <div className="col-lg-6 copy-right2 text-right privacy-T-C">
              <span className="t-c-border">
                <Link to="/terms-and-conditions" className="text-white mr-2">
                  Terms and Conditions
                </Link>
              </span>

              <span>
                <Link to="/privacy-policy" className="text-white mr-2">
                  Privacy Policy
                </Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Footer;
