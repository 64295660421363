import React from "react";
import { Link } from "react-router-dom";
import LogoL from "../../assets/img/logo.png";

const Header = () => {
  // *****************

  return (

    <div>

      <header>
        <div className="container">
          <div className="main-menu">
            <h1 className="logo">
              <Link className="navbar-brand" to="/">
                <img
                  src={LogoL}
                  alt="logo"
                />
              </Link>
            </h1>

            <nav className="navbar navbar-expand-lg navbar-light">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                id="menuButton"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="navbar-open-risponsiv">
                
                 
                  
                  <ul className="navbar-nav mr-auto">
                   
                    <div className="navicon-close" ><a><i class="fa fa-times" id="nav-close-icon" aria-hidden="true"></i></a></div>
                    <li className="nav-item">
                      <Link
                        className={"nav-link"}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        className={"nav-link"
                        }
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li className="nav-item services-items">
                      <Link
                        className={"nav-link"
                        }
                        to="/services"
                      >
                        Services
                      </Link>
                      <ul className="submenuitems-services">
                          <li>  <Link className={"nav-link"} to="/android-app-development-services">Android App Development</Link></li>
                          <li>  <Link className={"nav-link"} to="/web-development-services">Web Development</Link></li>
                          <li>  <Link className={"nav-link"} to="/iot-software-development-services">IOT Software Development</Link></li>
                          <li>  <Link className={"nav-link"} to="/ios-app-development-services">IOS App Development</Link></li>
                          <li>  <Link className={"nav-link"} to="/ui-ux-design-services">UI/UX Designing Services</Link></li>
                          <li>  <Link className={"nav-link"} to="/hybrid-app-development-services">Hybrid App Development</Link></li>
                          <li>  <Link className={"nav-link"} to="/wearable-app-development-services"> Wearable Development</Link></li>
                          <li>  <Link className={"nav-link"} to="/qa-testing">  QA testing</Link></li>
                          <li>  <Link className={"nav-link"} to="/gohigh-level">  Go HighLevel</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link"} to="/portfolio">
                        Case Study
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={"nav-link"} to="/life-at-elicit">
                      Life At Elicit
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={"nav-link"
                        }
                        to="/career"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={"nav-link"
                        }
                        to="/blog"
                      >
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link-black get-touch-btn">
                        <Link to="/get-in-touch" className="get_btn">
                          Get in Touch
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>

      </header>
    </div>
  );
};
export default Header;
